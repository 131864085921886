import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const Verify = () => {
  const { user_id, confirmation_token } = useParams();
  const [verificationStatus, setVerificationStatus] = useState("Verifying...");

  useEffect(() => {
    const verifyUser = async () => {
      try {
        // Make API call to verify user
        const response = await axios.get(
          `https://collarhire.com/user/activate/?user_id=${user_id}&confirmation_token=${confirmation_token}`
        );
        setVerificationStatus("Your Profile has been verified");
      } catch (error) {
        console.error("Error verifying user:", error);
        setVerificationStatus("Error verifying user. Please contact support.");
      }
    };

    verifyUser();
  }, [user_id, confirmation_token]);

  return (
    <div className="verifyContainer">
      <div className="verifyContent">
        <div className="iconContainer">
          <FontAwesomeIcon icon={faCircleCheck} />
        </div>
        <h3>{verificationStatus}</h3>
        <p>Please Sign In</p>
        <Link to="/signin">
          <button className="button">Sign In</button>
        </Link>
      </div>
    </div>
  );
};

export default Verify;
