import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignOut,
  faGear,
  faMessage,
  faPhone,
  faLocation,
} from "@fortawesome/free-solid-svg-icons";
import Img from "../../img/company.png";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";

import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { getEmployeeProfile, signout } from "../../apicalls";
import { useQuery } from "@tanstack/react-query";

const ProfileLayout = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { dispatch, token, type } = useContext(AuthContext);
  const [tabbtn, setTabBtn] = useState(0);

  useEffect(() => {
    console.log(profile);
  });

  const {
    isError,
    isLoading: profileLoading,
    data: profile,
  } = useQuery({
    queryKey: ["Profile", token],
    queryFn: getEmployeeProfile,
  });

  const employeeTabs = [
    { name: "Education", path: "/profile/education" },
    { name: "Experience", path: "/profile/experience" },
    { name: "Skill", path: "/profile/skill" },
    { name: "Resume", path: "/profile/resume" },
  ];

  const employerTabs = [
    { name: "Overview", path: "/profile" },
    { name: "View Members", path: "/profile/viewemployers" },
    { name: "Add Member", path: "/profile/addemployer" },
    { name: "Apikey", path: "/profile/apikey" },
    { name: "Current Plan ", path: "/profile/currentplan" },
  ];

  return (
    <div className="profileContainer">
      <div className="profileHeaderContainer shadow">
        <div className="profileMainSection">
          <div className="imageContainer">
            <img src={Img} />
          </div>

          <div className="textContainer">
            <div className="mainTextContent">
              <h2>{`${profile?.first_name} ${profile?.last_name}`}</h2>
              <h4>
                {type === "EMPLOYEE"
                  ? "Candidate"
                  : !profile?.admin
                  ? "Admin"
                  : "Moderator"}
              </h4>

              <div className="details">
                <FontAwesomeIcon icon={faMessage} />
                <p>{profile?.email}</p>
                <FontAwesomeIcon icon={faPhone} />
                <p>
                  {profile?.contact_number
                    ? profile?.contact_number
                    : "Contact number not added"}
                </p>
                <FontAwesomeIcon icon={faLocation} />
                <p>
                  {profile?.city && profile?.state && profile?.country
                    ? `${profile?.city}, ${profile?.state}, ${profile?.country}`
                    : !profile?.city && profile?.state && profile?.country
                    ? `${profile?.state}, ${profile?.country}`
                    : !profile?.city && !profile?.state && profile?.country
                    ? `${profile?.country}`
                    : ""}
                </p>
              </div>
            </div>

            <div className="buttonContainer">
              <button
                onClick={() => {
                  navigate("/editprofile");
                }}
              >
                <FontAwesomeIcon icon={faGear} />
              </button>
              <button
                onClick={() => {
                  signout(dispatch);
                  navigate("/");
                }}
              >
                <FontAwesomeIcon icon={faSignOut} />
              </button>
            </div>
          </div>
        </div>

        <div className="profileNavbarContainer">
          {type === "EMPLOYER" &&
            employerTabs.map((e) => {
              if (
                (e.name === "Add Members" || e.name === "View Members") &&
                profile?.admin
              )
                return;
              return (
                <Link
                  className={
                    `navText ` + (location.pathname === e.path && "active")
                  }
                  to={e.path}
                >
                  {e.name}
                </Link>
              );
            })}

          {type === "EMPLOYEE" &&
            employeeTabs.map((e) => {
              return (
                <Link
                  className={
                    `navText ` + (location.pathname === e.path && "active")
                  }
                  to={e.path}
                >
                  {e.name}
                </Link>
              );
            })}
        </div>
      </div>

      <div className="profileContent">
        {props.children ? props.children : <Outlet />}
      </div>
    </div>
  );
};

export default ProfileLayout;
