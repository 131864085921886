import {
  faFacebook,
  faInstagram,
  faLinkedinIn,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faCheckCircle, faMessage } from "@fortawesome/free-regular-svg-icons";
import {
  faBriefcase,
  faEnvelope,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Heading1 from "../components/common/Heading1/heading1";
import ContactImg1 from "../img/contactImg1.png";
import Breadcrumbs from "../components/common/Breadcrumbs/breadcrumbs";
import SubHeading1 from "../components/common/SubHeading1/subHeading1";

import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
export const BaseUrl = process.env.REACT_APP_BASE_URL;
export const apikey = process.env.REACT_APP_APIKEY;
const Contact = () => {
  const [form, setForm] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const isValidEmail = (email) => {
    // Basic email validation regex
    return /\S+@\S+\.\S+/.test(email);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isValidEmail(form.email)) {
      toast.error("Please enter a valid email address.");
      return;
    }
    const formData = {
      name: document.getElementById("name").value,
      email: document.getElementById("email").value,
      subject: document.getElementById("subject").value,
      message: document.getElementById("message").value,
    };
    try {
      await axios.post('${BaseUrl}/contact/contact/', formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success("Your Message sent successfully! We'll get back to you as soon as possible.");
  
      setForm({ name: "", email: "", subject: "", message: "" }); // Clear form data
    } catch (error) {
      toast.error("Failed to send message. Please try again later.");
      console.error(error); // debugging
    }
  };
  return (
    <>
      <div className="banner">
        <div className="bannerContent">
          <h1>Contact Us</h1>
        </div>
      </div>

      <div style={{ maxWidth: 1200, margin: "0 auto", padding: "0 15px" }}>
        <Breadcrumbs text="Contact Us" />
      </div>
      <div className="contactContainer">
        <div className="contactContent">
          <div className="header">
            <div className="textContainer">
              <SubHeading1 text="Your Personal Demo" />
              <p className="mainText">
                Get to know Collar Hire! Our product experts will guide you
                through our solution:
              </p>
              <div>
                <div className="list">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  <p>Introduction to all product features</p>
                </div>
                <div className="list">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  <p>Important features for your business priorities</p>
                </div>
                <div className="list">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  <p>Answers to any questions you may have</p>
                </div>
              </div>
            </div>

            <div className="imageContainer">
              <img src={ContactImg1} />
            </div>
          </div>

          <div className="mainContent">
            <div className="contentSubHeading">
              <SubHeading1 text="Book Your Free Demo" />
              <p>
                We will only contact you to book an appoint that works for you
                and understand your specific requirements.
              </p>
            </div>

            <div className="mainSection">
              <form
                class="contactform"
                onSubmit={handleSubmit}
              >
                <div className="grid">
                  <div>
                  <label>Name</label>
      <input
        type="text"
        id="name"
        className="form-control"
        placeholder="Name"
        value={form.name}
        onChange={(e) => setForm({ ...form, name: e.target.value })}
        required
      />
    </div>
    <div>
      <label>Email</label>
      <input
        type="email"
        id="email"
        className="form-control"
        placeholder="Email"
        value={form.email}
        onChange={(e) => setForm({ ...form, email: e.target.value })}
        required
      />
      <small className="error-text">
        {!isValidEmail(form.email) && "Please enter a valid email address."}
      </small>
    </div>
  </div>
  <div>
    <label>Subject</label>
    <input
      type="text"
      id="subject"
      className="form-control"
      placeholder="Subject"
      value={form.subject}
      onChange={(e) => setForm({ ...form, subject: e.target.value })}
      required
    />
  </div>
  <div>
    <label>Message</label>
    <textarea
      id="message"
      className="form-control height-120"
      placeholder="Message"
      value={form.message}
      onChange={(e) => setForm({ ...form, message: e.target.value })}
      required
    ></textarea>
                </div>
                <button
                  class="btn theme-btn"
                  name="submit"
                  style={{ borderRadius: 30, fontSize: 12 }}
                >
                  Send Message
                </button>
              </form>

              <div className="socialsContainer">
                <div className="contactRowContainer">
                  <div className="contactRow">
                    <div className="btnContainer">
                      <FontAwesomeIcon icon={faEnvelope} />
                    </div>

                    <div>
                      <p className="primary">Email</p>
                      <p className="secondary">help@collarhire.com</p>
                    </div>
                  </div>

                  <div className="contactRow">
                    <div className="btnContainer">
                      <FontAwesomeIcon icon={faPhone} />
                    </div>

                    <div>
                      <p className="primary">Phone</p>
                      <p className="secondary">+233 50 660 8337</p>
                    </div>
                  </div>

                  <div className="contactRow">
                    <div className="btnContainer ">
                      <FontAwesomeIcon icon={faBriefcase} />
                    </div>

                    <div>
                      <p className="primary">Looking for a job?</p>
                      <p className="secondary">careers@collarhire.com</p>
                    </div>
                  </div>
                </div>

                <Heading1 text="Connect Us On" />
                <div className="socialBtnContainer">
                  <div className="btnContainer">
                    <FontAwesomeIcon icon={faLinkedinIn} />
                  </div>

                  <div className="btnContainer">
                    <FontAwesomeIcon icon={faFacebook} />
                  </div>

                  <a
                    href="https://instagram.com/morrisonrecordsbureau?igshid=Zjc2ZTc4Nzk="
                    target="_blank"
                    className="btnContainer"
                  >
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>

                  <div className="btnContainer">
                    <FontAwesomeIcon icon={faTwitter} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
